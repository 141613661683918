import { useModal, Right as RightSvg, useDesktopMediaQuery } from "Components";
import { MarketLookupInternal } from "Components/Modals/MarketLookup";
import { lazy, Suspense, useEffect, useState } from "react";
import styled from "styled-components";
const Map = lazy(() => import("./Components/Map"));

export const Landing = () => {
  const { setModal } = useModal();
  const isDesktop = useDesktopMediaQuery();
  const [showZip, setShowZip] = useState(false);

  useEffect(() => {
    if (isDesktop) {
      setModal({
        marketLookup: true,
      });
    }
  }, []);

  if (!isDesktop) {
    return (
      <MobileContainer>
        <MarketLookupInternal showZip={showZip} setShowZip={setShowZip} />
      </MobileContainer>
    );
  }

  return (
    <Container>
      <Header>
        <Left>
          <Title>Ford Content</Title>
          <SubTitle>Central Market</SubTitle>
        </Left>
        <Right>
          <Link onClick={() => setModal({ marketLookup: { zip: true } })}>
            Search by Zip Code
            <RightSvg />
          </Link>
        </Right>
      </Header>
      <Suspense fallback={<div>Loading...</div>}>
        <Map />
      </Suspense>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: center;
  padding: 20px 120px;
  background: var(--blue1);
  color: #fff;
`;

const Left = styled.div`
  flex-grow: 1;
  text-align: left;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 24px;
  padding-bottom: 8px;
  font-style: italic;
`;

const SubTitle = styled.div`
  font-size: 14px;
`;

const Link = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--blue1);
  padding: 40px;
  min-height: 100vh;
  text-align: center;
`;
