import {
  createContext,
  useState,
  useEffect,
  useContext,
  FC,
  PropsWithChildren,
} from "react";
import { initializeApp, getApps } from "firebase/app";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import * as firestore from "firebase/firestore";
import "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref as storageRef } from "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const app = initializeApp(config);
const db = firestore.getFirestore();
const auth = getAuth(app);

const storage = getStorage();

const functions = getFunctions(app);

export const FirebaseContext = createContext(undefined);

export const FirebaseProvider: FC<PropsWithChildren> = ({ children }) => {
  const logout = async () => signOut(auth);

  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      setUser(user);
    });
  }, []);

  const callable = async (name: string, data: { [key: string]: any }) => {
    const func = httpsCallable(functions, name);
    try {
      return await func(data);
    } catch (error) {
      console.log(error);
      return { error };
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        firebase: app,
        db,
        auth,
        logout,
        ...firestore,
        callable,
        user,
        storage,
        storageRef,
      }}
    >
      {getApps().length > 0 && children}
    </FirebaseContext.Provider>
  );
};

export const useFirebaseContext = () => {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error(
      "useFirebaseContext must be used within a Firebase Provider"
    );
  }
  return context;
};
