import Modal from "react-modal";
import { doc, getDoc } from "firebase/firestore";
import {
  fdafMap,
  fdafMapInverse,
  useFirebaseContext,
  useModal,
  Location,
  useLocation,
  useDesktopMediaQuery,
} from "Components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export const MarketLookup = () => {
  const {
    modal: { marketLookup },
    setModal,
  } = useModal();
  const setShowZip = (zip) => {
    setModal({ marketLookup: { zip } });
  };
  return (
    <Appearance isOpen={!!marketLookup} onRequestClose={() => setModal({})}>
      <MarketLookupInternal
        showZip={marketLookup?.zip}
        setShowZip={setShowZip}
      />
    </Appearance>
  );
};

export const MarketLookupInternal = ({ showZip, setShowZip }) => {
  const { location, hasLocationError, locationLoading } = useLocation();
  const { db } = useFirebaseContext();
  const navigate = useNavigate();
  const coder = new google.maps.Geocoder();
  const [result, setResult] = useState<any>();
  const { setModal } = useModal();
  const isDesktop = useDesktopMediaQuery();
  const [zip, setZip] = useState("");
  const [loading, setLoading] = useState(!showZip);
  const [error, setError] = useState(false);
  const [select, setSelect] = useState(Object.values(fdafMap)[0]);

  const handleContinue = () => {
    navigate(`/${result}`);
    setModal({});
  };

  useEffect(() => {
    if (location) {
      coder
        .geocode({
          location: { lat: location.latitude, lng: location.longitude },
        })
        .then((r) => {
          const zip = r.results[0].address_components.find((a) =>
            a.types.find((a) => a === "postal_code")
          )?.short_name;
          getDoc(doc(db, `/geo/ford/fdaf/${zip}`))
            .then((r) => {
              if (fdafMap[r?.data()?.fdaf]) {
                setResult(fdafMap[r.data().fdaf]);
              } else {
                if (isDesktop) {
                  setModal({});
                }
                setResult("nomatch");
              }
            })
            .finally(() => setLoading(false));
        });
    }
  }, [location]);

  useEffect(() => {
    if (hasLocationError) {
      if (isDesktop && !showZip) {
        setTimeout(() => {
          setModal({});
        }, 3000);
      } else {
        setShowZip(true);
      }
    }
  }, [hasLocationError]);

  const getMarket = (zip) => {
    getDoc(doc(db, `/geo/ford/fdaf/${zip}`)).then((r) => {
      if (fdafMap[r.data()?.fdaf]) {
        navigate(`/${fdafMap[r.data().fdaf]}`, { replace: true });
      } else {
        navigate(`/nomatch`);
      }
      setModal({});
    });
  };

  const handleZipInput = (e) => {
    const val = e.target.value.replace(/\D/g, "").trim().substring(0, 5);
    setZip(val);
  };

  const handleSubmit = () => {
    if (zip.length !== 5) {
      setError(true);
    } else {
      setError(false);
      getMarket(zip);
    }
  };

  return (
    <>
      <Header>Ford Content</Header>
      {showZip ? (
        <ZipContainer>
          <Label style={{ margin: "10px 0 20px 0" }}>
            Please enter your zip code:
          </Label>
          <ZipInput
            type="telephone"
            value={zip}
            onChange={handleZipInput}
            inputMode="numeric"
          />
          {error && <Error>Please enter a 5 digit Zip Code</Error>}
          <Button style={{ marginBottom: "10px" }} onClick={handleSubmit}>
            Submit
          </Button>
          {!isDesktop && (
            <>
              <Or style={{ margin: "20px 0" }} />
              <Label style={{ margin: "10px 0 20px 0" }}>
                Select your market area
              </Label>
              <Select
                value={select}
                onChange={(e) => setSelect(e.target.value)}
              >
                {Object.keys(fdafMap).map((key) => (
                  <option key={key} value={fdafMap[key]}>
                    {key}
                  </option>
                ))}
              </Select>
              <Button
                style={{ marginBottom: "10px" }}
                onClick={() => {
                  navigate(`/${select}`);
                }}
              >
                Submit
              </Button>
            </>
          )}
        </ZipContainer>
      ) : (
        <>
          <Location width="36" height="36" style={{ marginBottom: "20px" }} />
          {!(locationLoading || loading) &&
            (result !== "nomatch" ? (
              <>
                <Text style={{ marginBottom: "20px" }}>
                  You are in the <b>{fdafMapInverse[result]}</b> Region
                </Text>
                <Text>Is this correct?</Text>
                <Button onClick={handleContinue}>Continue</Button>
                <Or />
                <Button onClick={() => setShowZip(true)}>
                  {isDesktop ? "Search by Zip" : "Find Your Region"}
                </Button>
              </>
            ) : (
              <>
                <Text>You are in an currently unserved Region</Text>
                <Button
                  style={{ marginBottom: "30px" }}
                  onClick={() => {
                    navigate(`/nomatch`);
                    setModal({});
                  }}
                >
                  Get Info
                </Button>
                <Or />
                <Button
                  onClick={() => {
                    setShowZip(true);
                  }}
                >
                  Select Region
                </Button>
              </>
            ))}
          {(locationLoading || loading) && !hasLocationError && (
            <Text>Getting Your Location...</Text>
          )}
          {hasLocationError && <Text>Location not found... </Text>}
        </>
      )}
    </>
  );
};

const Appearance = styled(Modal)`
  position: relative;
  top: 50%;
  max-width: 450px;
  margin: 0 auto;
  transform: translate(0, -50%);
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 40px 120px;
  color: var(--blue1);
  text-align: center;
  border-radius: 4px;
  &:focus-visible {
    outline: none;
  }
`;

const Header = styled.div`
  font-size: 28px;
  font-style: italic;
  margin-bottom: 20px;
`;

const Text = styled.div`
  font-size: 18px;
  line-height: 24px;
  & b {
    font-weight: bold;
    display: block;
    font-size: 24px;
    line-height: 32px;
  }
`;

const Error = styled.div`
  padding: 20px 0 0 10px;
`;

const Button = styled.div`
  cursor: pointer;
  background: var(--blue1);
  height: 36px;
  border-radius: 18px;
  padding: 10px 40px;
  color: #fff;
  text-align: center;
  min-width: 200px;
  margin: 30px;
  &:hover {
    background: #1700f4;
  }
`;

const Label = styled.div`
  font-size: 14px;
  padding-bottom: 12px;
`;

const Or = ({ ...props }) => (
  <OrContainer {...props}>
    <OrLine />
    <OrText>Or</OrText>
  </OrContainer>
);
const OrContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 12px;
  max-width: 250px;
`;

const OrText = styled.div`
  text-align: center;
  background: #fff;
  width: 60px;
  z-index: 2;
`;

const OrLine = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  background: var(--grey2);
  height: 1px;
  width: 100%;
`;

const ZipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ZipInput = styled.input`
  max-width: 200px;
  padding: 8px;
  border: 1px solid var(--grey3);
  border-radius: 4px;
  font-size: 24px;
`;

const Select = styled.select`
  padding: 5px;
  font-size: 20px;
  border: 1px solid var(--grey3);
  border-radius: 4px;
`;
