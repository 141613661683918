import { socials } from "Components";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useMarket } from "./MarketContext";

export const SocialFeed = () => {
  const [service, setService] = useState(undefined);
  const [lang, setLang] = useState(false);
  const { document } = useMarket();
  let feed = document.social?.[service]?.[lang ? "feedsp" : "feed"];
  let hideSp = false;

  if (!document.social?.[service]?.feedsp) {
    feed = document.social?.[service]?.feed;
    hideSp = true;
  }

  useEffect(() => {
    for (const s of socials) {
      if (document.social?.[s.key]?.feed) {
        setService(s.key);
        return;
      }
    }
  }, []);

  useEffect(() => {
    if (feed) {
      (function (d, s, id) {
        var js;
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://embedsocial.com/cdn/ht.js";
        d.getElementsByTagName("head")[0].appendChild(js);
      })(window.document, "script", "EmbedSocialHashtagScript");
      return () => {
        window.document.getElementById("EmbedSocialHashtagScript")?.remove();
      };
    }
  }, [feed]);
  if (!feed) {
    return null;
  }
  return (
    <Container>
      <Header>Stay connected with your community</Header>
      <Options>
        {socials.map(({ key, Image }) =>
          document.social[key]?.feed ? (
            <Social
              key={key}
              style={{ background: key === service ? "var(--blue1)" : "#fff" }}
              onClick={() => setService(key)}
            >
              <Image
                style={{ color: key === service ? "#fff" : "var(--blue1)" }}
              />
            </Social>
          ) : null
        )}
        <Languages>
          <Language onClick={() => setLang(false)} selected={!lang || hideSp}>
            EN
          </Language>
          /
          <Language
            onClick={() => !hideSp && setLang(true)}
            selected={lang}
            disabled={hideSp}
          >
            ES
          </Language>
        </Languages>
      </Options>
      <div className="embedsocial-hashtag" data-ref={feed}></div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1023px) {
    margin: 0 auto;
    width: 80%;
  }
`;

const Header = styled.div`
  font-size: 28px;
  color: var(--blue1);
  text-align: center;
  padding: 30px 0 20px 0;
`;

const Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
`;

const Social = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  background: #fff;
  border: 1px solid var(--blue1);
  border-radius: 4px;
  margin: 5px;
  &:hover {
  }
`;

const Languages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
`;

const Language = styled.div<{ selected: boolean; disabled?: boolean }>`
  cursor: pointer;
  color: var(--blue1);
  font-size: 24px;
  margin: 4px;
  ${({ selected }) => selected && `color: var(--blue2);`}
  ${({ disabled }) => disabled && `color: var(--grey3); cursor: default;`}
`;
