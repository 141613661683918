import { socials } from "Components";
import styled from "styled-components";
import { useMarket } from "./MarketContext";
import fordTexasLogo from "Assets/fordTexasLogo.png";
import fordLogo from "Assets/fordLogo2.png";

export const Header = () => {
  const { document } = useMarket();

  let logo: string;
  document?.name === "Cincinnati North" ||
  document?.name === "Mid-South" ||
  document?.name === "St Louis"
    ? (logo = fordLogo)
    : (logo = fordTexasLogo);

  return (
    <Container>
      <TitleBox>
        <Logo src={logo} />
        <Title>{document?.name} Ford Content</Title>
      </TitleBox>
      <Socials>
        <div>Follow Us:</div>
        {socials.map(({ key, Image }) => (
          <Image
            key={key}
            style={{
              cursor: document.social?.[key]?.url ? "pointer" : "default",
              color: document.social?.[key]?.url
                ? `var(blue1)`
                : `var(--grey3)`,
            }}
            onClick={
              document.social?.[key]?.url
                ? () => {
                    window.open(document.social?.[key]?.url, "_blank");
                  }
                : null
            }
          />
        ))}
      </Socials>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 0px 30px 40px;

  @media (max-width: 1023px) {
    background-color: var(--grey1);
    flex-direction: column-reverse;
    padding: 0;
    width: 100%;
    margin: 0 auto 10px auto;
    overflow: hidden;
  }
`;

const Title = styled.div`
  font-size: 16px;
  color: var(--blue1);
  font-weight: 400;
  text-transform: uppercase;
`;

const Socials = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-left: 50px;
  font-size: 12px;
  color: var(--blue1);
  & > * {
    margin-left: 20px;
  }

  @media (max-width: 1023px) {
    align-self: center;
    margin: 0;
    padding: 10px 20px 20px 10px;

    & > * {
      margin-left: 15px;
    }
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;

  @media (max-width: 1023px) {
    padding-top: 5px;
    justify-content: center;
    background-color: #fff;
  }
`;

const Logo = styled.img`
  width: 50px;
  margin: 0px 25px 0 -10px;

  @media (max-width: 1023px) {
    margin: 15px 20px 10px 0px;
  }
`;
