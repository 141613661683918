import { createContext, useContext, useState } from "react";
import Modal from "react-modal";
import { SubmitEvent } from "./SubmitEvent";
import { EventDetails } from "./EventDetails";
import { MarketLookup } from "./MarketLookup";

Modal.setAppElement("#root");

const ModalContext = createContext(undefined);
export const ModalProvider = ({ children, ...props }) => {
  const [modal, setModal] = useState({});

  return (
    <ModalContext.Provider value={{ modal, setModal }} {...props}>
      <SubmitEvent />
      <EventDetails />
      <MarketLookup />
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
