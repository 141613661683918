import * as React from "react";
import { SVGProps } from "react";
export const Right = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.29289322,3.29289322 C6.65337718,2.93240926 7.22060824,2.90467972 7.61289944,3.20970461 L7.70710678,3.29289322 L14.4142136,10 L7.70710678,16.7071068 C7.31658249,17.0976311 6.68341751,17.0976311 6.29289322,16.7071068 C5.93240926,16.3466228 5.90467972,15.7793918 6.20970461,15.3871006 L6.29289322,15.2928932 L11.585,10 L6.29289322,4.70710678 C5.93240926,4.34662282 5.90467972,3.77939176 6.20970461,3.38710056 L6.29289322,3.29289322 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
