import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMarket } from "./MarketContext";

export const ChangeMarket = () => {
  const navigate = useNavigate();
  const { document } = useMarket();
  return (
    <Container>
      <p>
        You are in the <b style={{ fontWeight: 800 }}>{document.name} Region</b>{" "}
        <span
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          Not your Region?
        </span>
      </p>
    </Container>
  );
};

const Container = styled.div`
  background: var(--grey1);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 12px;
  padding: 4px calc(4px + 10%) 4px 4px;
  color: var(--blue1);

  @media (max-width: 1023px) {
    justify-content: center;
    padding: 10px 0;
  }
`;
