import { useEffect, useRef } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

export const GoogleMapProvider = (props) => (
  <Wrapper
    apiKey={"AIzaSyDud_XydTELr9rrakr3Zf1dAlIB6I_pZB8"}
    render={() => null}
    libraries={["places"]}
    {...props}
  />
);

export const GoogleMap = ({ query }) => {
  const ref = useRef();

  const request = {
    query,
    fields: ["name", "geometry"],
  };

  useEffect(() => {
    if (!query) {
      return;
    }
    const map = new google.maps.Map(ref.current, {
      center: { lat: 99.9018, lng: 31.9686 },
      zoom: 13,
    });
    const infowindow = new google.maps.InfoWindow({ content: query });
    const service = new google.maps.places.PlacesService(map);
    service.findPlaceFromQuery(request, function (results, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        for (var i = 0; i < results.length; i++) {
          const marker = new google.maps.Marker({
            map,
            position: results[i].geometry.location,
          });
          infowindow.open({}, marker);
        }
        map.setCenter(results[0].geometry.location);
      }
    });
  });

  if (!query) {
    return null;
  }

  return <div style={{ width: "100%", height: "400px" }} ref={ref} id="map" />;
};
