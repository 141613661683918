import styled from "styled-components";
import fordTexasLogo from "Assets/fordTexasLogo.png";
import fordLogo from "Assets/fordLogo2.png";
import { useMarket } from "./MarketContext";

export const Footer = () => {
  const { document } = useMarket();

  let logo: string;
  document?.name === "Cincinnati North" ||
  document?.name === "Mid-South" ||
  document?.name === "St Louis"
    ? (logo = fordLogo)
    : (logo = fordTexasLogo);

  return (
    <Container>
      <TitleBox>
        <Logo src={logo} />
      </TitleBox>
      <LinkContainer>
        <Link
          href="https://www.buyfordnow.com/dallas-plano-arlington/"
          target="_blank"
          style={{ color: "var(--blue1)" }}
          rel="noreferrer"
        >
          Incentives & Offers
        </Link>
        <Link
          href="https://shop.ford.com/showroom#/"
          target="_blank"
          style={{ color: "var(--blue1)" }}
          rel="noreferrer"
        >
          Build & Price
        </Link>
        <Link
          href="https://www.buyfordnow.com/dallas-plano-arlington/compare-cars-trucks-suvs/side-by-side-comparison?intcmp=t2-fdaf-52b-hp-comp"
          target="_blank"
          style={{ color: "var(--blue1)" }}
          rel="noreferrer"
        >
          Compare Ford
        </Link>
        <Link
          href="https://www.buyfordnow.com/dallas-plano-arlington/vehicle-pricing-quote/car-truck-suv?gnav=t2-header-tools"
          target="_blank"
          style={{ color: "var(--blue1)" }}
          rel="noreferrer"
        >
          Get a Quote
        </Link>
      </LinkContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 40px;
  background-color: #f2f2f2;
  align-items: center;

  @media (max-width: 1023px) {
    width: 100%;
    overflow: hidden;
    padding: 10px 0;
  }
`;

const LinkContainer = styled.div`
  flex: 6;
  margin-left: 50px;
  font-size: 12px;
  color: var(--blue1);
  & > * {
    margin-left: 20px;
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
  }
`;

const TitleBox = styled.div`
  flex: 1;
`;

const Logo = styled.img`
  width: 50px;
  margin: 10px 0 10px 40px;

  @media (max-width: 1023px) {
    margin: 0px 10px 0 70px;
  }
`;

const Link = styled.a`
  margin: 0 40px;
  text-decoration: none;

  @media (max-width: 1023px) {
    margin: 5px 20px;
  }
`;
