import styled from "styled-components";
import { useMarket } from "./MarketContext";

export const CommunityManager = () => {
  const { document } = useMarket();

  if (!document.manager.name) return null;

  return (
    <Container>
      <DesktopContainer>
        <Image src={document.manager.image.url} />
      </DesktopContainer>
      <Content>
        <Header>
          Meet your {document.title} Community Manager, {document.manager.name}
        </Header>
        <MobileContainer>
          <Image src={document.manager.image.url} />
        </MobileContainer>
        <Bio>{document.manager.bio}</Bio>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: var(--grey1);
  padding: 40px 80px;
  margin-top: 40px;

  @media (max-width: 1023px) {
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    padding: 40px;
    margin-top: 30px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--blue1);
  padding-left: 20px;
  @media (max-width: 1023px) {
    text-align: center;
    padding: 10px;
    order: 0;
  }
`;

const DesktopContainer = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;

const MobileContainer = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: block;
    order: 1;
    margin-bottom: 15px;
  }
`;

const Header = styled.div`
  font-size: 28px;
  line-height: 140%;
  padding-bottom: 20px;

  @media (max-width: 1023px) {
    font-size: 23px;
    padding: 0 10px 20px 10px;
  }
`;
const Bio = styled.div`
  line-height: 140%;

  @media (max-width: 1023px) {
    order: 2;
    font-size: 14px;
  }
`;
const Image = styled.img`
  padding-right: 40px;
  width: 350px;

  @media (max-width: 1023px) {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
  }
`;
