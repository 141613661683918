import { createContext, useContext, useEffect, useState } from "react";
import ApiCalendar from "react-google-calendar-api";

const config = {
  clientId:
    "734601945776-a1fk0qpuq7p0hubj65ne4ghfjftbc2tg.apps.googleusercontent.com",
  apiKey: "AIzaSyCQh9j81W28QYgnJDg-P9XbcjjRph2Sybs",
  scope: "https://www.googleapis.com/auth/calendar",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
};

const CalendarContext = createContext<ApiCalendar>(undefined);

export const CalendarProvider = (props) => {
  const [calendar, setCalendar] = useState<ApiCalendar>(undefined);
  useEffect(() => {
    const googleCalendar = new ApiCalendar(config);
    googleCalendar.onLoadCallback = () => {
      setCalendar(googleCalendar);
    };
  }, []);
  return <CalendarContext.Provider value={calendar} {...props} />;
};

export const useCalendar = () => useContext(CalendarContext);
