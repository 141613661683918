import * as React from "react";
import { SVGProps } from "react";
export const Clock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10,2 C14.418278,2 18,5.581722 18,10 C18,14.418278 14.418278,18 10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 Z M10.5,11 L10.5,4 L9.5,4 L9.5,10 L6,10 L6,11 L10.5,11 Z"
      fill="white"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
