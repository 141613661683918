import { Facebook, Instagram, TikTok, Twitter } from "Components";

export const socials = [
  {
    key: "facebook",
    Image: Facebook,
  },
  { key: "twitter", Image: Twitter },
  {
    key: "instagram",
    Image: Instagram,
  },
  {
    key: "tiktok",
    Image: TikTok,
  },
];
