import { useModal } from "Components";
import styled from "styled-components";

export const SubmitEvent = () => {
  const { setModal } = useModal();
  return (
    <Container>
      <Header>Have an event?</Header>
      <Button onClick={() => setModal({ submitEvent: true })}>
        Tell Us About It
      </Button>
    </Container>
  );
};

const Container = styled.div`
  background: var(--blue1);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 40px 200px;
  margin: 40px 0;

  @media (max-width: 1023px) {
    flex-direction: column;
    padding: 20px 20px;
    margin: 20px 0;
    text-align: center;
  }
`;

const Header = styled.div`
  color: #fff;
  font-size: 24px;

  @media (max-width: 1023px) {
    padding: 10px 0 20px 0;
  }
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding: 0 60px;
  background: #fff;
  color: var(--blue1);
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    background: #1700f4;
    color: #fff;
  }

  @media (max-width: 1023px) {
    font-size: 16px;
    flex-direction: column;
    height: 30px;
    width: 60%;
    justify-content: center;
    align-self: center;
    padding: 6px 10px 7px 10px;
    margin: 10px auto;
    text-align: center;
  }
`;
