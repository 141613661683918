import Modal from "react-modal";
import { useModal } from "Components";
import { useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "Components/useMediaQuery";

export const SubmitEvent = () => {
  const isDesktop = useMediaQuery("(min-width: 1023px)");

  const {
    modal: { submitEvent },
    setModal,
  } = useModal();
  useEffect(() => {
    if (submitEvent) {
      (function (d, s, id) {
        var js;
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://embedsocial.com/cdn/ef.js";
        d.getElementsByTagName("head")[0].appendChild(js);
      })(document, "script", "EmbedSocialFormsScript");
    }
    return () => {
      document.getElementById("EmbedSocialFormsScript")?.remove();
    };
  }, [submitEvent]);
  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.8)",
        },
        content: {
          position: "absolute",
          top: "0px",
          left: "initial",
          right: "0px",
          margin: "0 auto",
          bottom: "0px",
          border: "1px solid #ccc",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "0px",
          outline: "none",
          padding: "20px",
          width: isDesktop ? "50%" : "100%",
          backgroundColor: "#f7f7f7",
        },
      }}
      isOpen={submitEvent}
    >
      <Button onClick={() => setModal({})}>x</Button>
      <FormContainer>
        <Text>Event Details</Text>
        <FormWrapper
          className="embedsocial-forms-iframe"
          data-ref="7d52a6bf8ccc3482d2a97219ebe164a31b8f00f3"
          data-widget="true"
          data-height="auto"
        ></FormWrapper>
      </FormContainer>
    </Modal>
  );
};

const Button = styled.button`
  background: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Text = styled.div`
  align-self: center;
  font-size: 20px;
  color: #00095b;
  margin-top: 10px;
  margin-bottom: -25px;
  z-index: 100;
`;

const FormWrapper = styled.div``;
