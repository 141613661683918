import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useMediaQuery } from "Components/useMediaQuery";
import styled from "styled-components";
import { Footer } from "../Market/Components";
import Modal from "react-modal";
import PlayBtn from "../../Assets/PlayBtn.png";
import backgroundDesktop from "../../Assets/ReelImage.jpg";
import backgroundMobile from "../../Assets/ReelImageMobile.jpg";

export const NoMatch = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const isDesktop = useMediaQuery("(min-width: 1023px)");

  return (
    <Container>
      <Header>
        <p>
          We didn't find a match for this zip code.
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              paddingLeft: "6px",
            }}
            onClick={() => navigate("/")}
          >
            Try again?
          </span>
        </p>
      </Header>
      <Body>
        <Title>Ford Content</Title>
        <ImageContainer>
          <PlayButton onClick={handleOpen} src={PlayBtn} />
          <Text onClick={handleOpen}>Engage with your community</Text>
          <Modal
            style={{
              overlay: {
                backgroundColor: "rgba(0,0,0,0.8)",
              },
              content: {
                border: "0px solid #ccc",
                background: "#000",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "0px",
                outline: "none",
                padding: "20px",
                height: isDesktop ? "initial" : "40%",
              },
            }}
            isOpen={open}
            onRequestClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <YoutubeFrame
              src="https://www.youtube.com/embed/rs938dYXjYc"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></YoutubeFrame>
          </Modal>
        </ImageContainer>
        <MobileContainer>
          <Heading>Your Ford Region</Heading>
          <SubHeading>
            We noticed you’re currently not participating in Ford Content.{" "}
          </SubHeading>
          <TextBody>
            <p>
              We have a team of Community Managers (CM) representing a specific
              region for Ford. Each CM is in charge of establishing a core
              audience online and in the community. Community Managers are the
              face and ambassadors of the brand, representing them at community
              events across their region.
            </p>
            <br />

            <p>
              The Community Manager is responsible for attending and promoting
              community events to amplify brand partnerships and nurture the
              relationship of the brand within the community.
            </p>
            <br />
            <p>Interested in learning more? Contact us below!</p>
          </TextBody>
        </MobileContainer>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 1023px) {
    width: 100%;
    margin: 0;
    overflow: hidden;
  }
`;

const MobileContainer = styled.div`
  @media (max-width: 1023px) {
    width: 100%;
    margin: 0;
    padding: 20px;
  }
`;

const Header = styled.div`
  background: var(--grey1);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 12px;
  padding: 4px calc(4px + 10%) 4px 4px;
  color: var(--blue1);
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 15px;
  color: var(--blue1);
  font-weight: 400;
  text-transform: uppercase;
  padding: 30px 60px;

  @media (max-width: 1023px) {
    text-align: center;
  }
`;

const Heading = styled.div`
  font-size: 20px;
  color: var(--blue1);
  font-weight: 400;
  padding: 30px;
  text-align: center;
`;

const SubHeading = styled.div`
  font-size: 15px;
  color: var(--blue1);
  font-weight: 400;
  text-align: center;
`;

const TextBody = styled.div`
  font-size: 13px;
  color: var(--blue1);
  font-weight: 400;
  padding: 30px;
  text-align: center;
  line-height: 150%;
`;

const Image = styled.img`
  width: 100%;

  @media (max-width: 1023px) {
    width: 100%;
    margin: 0;
  }
`;

const ImageContainer = styled.div`
  background: url(${backgroundDesktop});

  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  background-size: cover;
  align-items: center;
  justify-content: center;

  @media (max-width: 1023px) {
    background: url(${backgroundMobile});
    background-size: contain;
  }
`;

const PlayButton = styled.img`
  width: 50px;
  padding: 200px 0 10px 0;
  cursor: pointer;
  margin: 0 auto;
`;
const Text = styled.p`
  color: white;
  font-size: 20px;
  padding-bottom: 200px;
  cursor: pointer;
`;

const YoutubeFrame = styled.iframe`
  width: 100%;
  height: 120%;
  margin: 0 auto;
  justify-content: center;
  background-color: black;

  @media (max-width: 1023px) {
    width: 100%;
    height: 80%;
    margin: 0 auto;
    background-color: black;
  }
`;
