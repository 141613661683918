import { useModal } from "Components";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useMarket } from "../MarketContext";
import { Event } from "./Components";
import { Content } from "./Components";

export const Upcoming = () => {
  const [view, setView] = useState("events");
  const { document, events, content } = useMarket();

  const { setModal } = useModal();

  useEffect(() => {
    if (
      events !== undefined &&
      content !== undefined &&
      !events.length &&
      content.length
    ) {
      setView("content");
    }
  }, [events, content]);

  return (
    <Container>
      <Left>
        See how your {document.secondaryName ?? document.name} Ford Dealers
        support their communities every day.
      </Left>
      <Right>
        <Header>
          {!!events.length && (
            <Title
              active={view === "events" ? true : false}
              onClick={() => setView("events")}
              solo={!content.length}
            >
              {view === "events" ? "Upcoming Events" : "View Upcoming Events"}
            </Title>
          )}
          {!!content.length && (
            <Switch
              active={view === "content" ? true : false}
              onClick={() => setView("content")}
              solo={!events.length}
            >
              {view === "content"
                ? "Upcoming Content"
                : "View Upcoming Content"}
            </Switch>
          )}
        </Header>
        <ScrollContainer>
          {view === "events"
            ? events.map((e) => (
                <Event
                  key={e.id}
                  event={e}
                  onClick={() => setModal({ eventDetails: e })}
                />
              ))
            : content.map((e) => <Content key={e.id} event={e} />)}
        </ScrollContainer>
      </Right>
    </Container>
  );
};

type Props = {
  active?: boolean;
  children?: any;
  solo?: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 400px;

  @media (max-width: 1023px) {
    flex-direction: column;
    width: 100%;
    height: 500px;
    overflow-y: scroll;
  }
`;
const Left = styled.div`
  background-image: url("/Ford_TerrainTreads.png");
  line-height: 150%;
  color: var(--grey1);
  font-size: 23px;
  flex: 1;
  padding: calc(10px + 2%) 70px calc(10px + 2%) calc(10px + 2%);

  @media (max-width: 1023px) {
    padding: 40px;
  }
`;
const Right = styled.div`
  display: flex;
  flex-grow: 1;
  flex: 3;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--grey1);
  border-bottom: 5px solid #00095b;
`;

const Switch = styled.div<Props>`
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 14px;
  background: var(--grey1);
  border-bottom: 5px solid ${(props) => (props.active ? "#1700f4" : "#f7f7f7")};
  color: ${(props) => (props.active ? "#1700f4" : "#00095b")};
  cursor: pointer;
  padding: 20px;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  ${({ solo }) => solo && `justify-content: flex-start; padding-left: 40px;`}
`;

const Title = styled(Switch)`
  @media (min-width: 1024px) {
    margin-right: 10px;
  }
`;

const ScrollContainer = styled.div`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 10px;
    border: 1px solid var(--grey3);
    transform: translate3d(-20px, -20px, -20px);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--grey3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
