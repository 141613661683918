import { FC } from "react";
import styled from "styled-components";
import {
  Header,
  MarketProvider,
  Upcoming,
  SubmitEvent,
  ChangeMarket,
  SocialFeed,
  CommunityManager,
  Footer,
} from "./Components";
export const Market: FC = () => {
  return (
    <Container>
      <MarketProvider>
        <ChangeMarket />
        <Header />
        <Upcoming />
        <SocialFeed />
        <CommunityManager />
        <SubmitEvent />
        <Footer />
      </MarketProvider>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;
`;
