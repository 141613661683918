import Modal from "react-modal";
import { useModal, GoogleMap, Close, Clock, Location } from "Components";
import styled from "styled-components";
import { google } from "calendar-link";

export const EventDetails = () => {
  const {
    modal: { eventDetails },
    setModal,
  } = useModal();
  const handleClose = () => {
    setModal({});
  };

  const saveEvent = {
    title: eventDetails?.summary,
    description: eventDetails?.description,
    start: eventDetails?.start,
    duration: [3, "hour"],
  };

  //@ts-ignore
  const calendarLink = google(saveEvent);

  return (
    <ModalStyled isOpen={!!eventDetails} onRequestClose={handleClose}>
      <CloseButton onClick={handleClose} />
      <Header>{eventDetails?.summary}</Header>
      <Info>
        <Left>
          <InfoHeader>
            <Clock />
            When
          </InfoHeader>
          <Item>{eventDetails?.start.toFormat("EEEE, MMMM, dd")}</Item>
          <Item>{eventDetails?.start.toFormat("t ZZZZ")}</Item>
          <Item>
            <a target="_blank" rel="noreferrer" href={calendarLink}>
              Add to calendar
            </a>
          </Item>
        </Left>
        {eventDetails?.location && (
          <Right>
            <InfoHeader>
              <Location />
              Where
            </InfoHeader>
            <Item>{eventDetails?.location}</Item>
            <Item>
              <a
                href={`https://www.google.com/maps/dir/?api=1&destination=${eventDetails?.location}`}
                target="_blank"
                style={{ color: "var(--blue1)" }}
                rel="noreferrer"
              >
                Get Directions
              </a>
            </Item>
          </Right>
        )}
      </Info>
      <Description>{eventDetails?.description}</Description>
      <MapContainer>
        {eventDetails?.location && <GoogleMap query={eventDetails?.location} />}
      </MapContainer>
    </ModalStyled>
  );
};

const Header = styled.div`
  text-align: center;
  font-size: 21px;
  color: var(--blue1);
  line-height: 140%;
  padding: 0 90px;

  @media (max-width: 1023px) {
    font-size: 18px;
    padding: 0 30px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: var(--blue1);
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 300px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 300px;
`;

const InfoHeader = styled.div`
  text-transform: uppercase;
  margin: 12px;
  position: relative;
  svg {
    position: absolute;
    left: -30px;
    top: -3px;
  }
`;

const Item = styled.div`
  margin: 12px;
  font-size: 13px;
`;

const Description = styled.div`
  padding: 10px 40px;
  color: #00095b;
  font-size: 13px;
  text-align: justify;
  line-height: 140%;
  white-space: pre-line;
`;

const CloseButton = styled(Close)`
  color: var(--blue1);
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ModalStyled = styled(Modal)`
  padding: 40px 0;
  margin: 5vh auto;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  max-width: 700px;
  position: relative;
  max-height: 90vh;
  overflow: scroll;
`;

const MapContainer = styled.div`
  padding: 0;

  @media (max-width: 1023px) {
    padding: 1em;
  }
`;
