import { FC } from "react";
import styled from "styled-components";
import { Clock } from "Components";
import type { Event as EventType } from "../../MarketContext";
const getLocation = (event: { location: string }): string | void => {
  const match = event.location?.match(/, (?<name>[^,]+, [^ ]{2}) [\d]{5}/);
  if (match) {
    return match.groups.name;
  }
};

export const Event: FC<{
  event: EventType;
  onClick?: () => unknown;
}> = ({ event, ...props }) => {
  const locationName = getLocation(event);

  let description;

  if (event.description?.length > 150) {
    description = `${event.description.slice(0, 150)}`;
    const lastSpace = description.lastIndexOf(" ");
    description = event.description.slice(0, lastSpace);
  } else {
    description = event.description;
  }

  return (
    <Container {...props}>
      <Date>
        <Day>
          {event.start.weekdayLong}, {event.start.monthLong} {event.start.day}
          {event.isAllDay &&
            event.end &&
            !event.start.equals(event.end) &&
            ` - ${event.end.weekdayLong}, ${event.end.monthLong} ${event.end.day}`}
        </Day>
        <Day>{event.summary}</Day>
        <BtnContainer>
          {locationName && <Description>{locationName}</Description>}
          <TimeDescription>
            <Clock />{" "}
            <Time>
              {event.isAllDay ? "All Day" : event.start.toFormat("t")}
            </Time>
          </TimeDescription>
        </BtnContainer>
      </Date>
      <EventDescription>
        {description}
        <Span> ... see more</Span>
      </EventDescription>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: var(--grey1);
  padding: 20px;
  border-bottom: 2px solid var(--grey3);
  align-items: flex-start;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;
const Date = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 10px 5px 5px;
  width: 50%;
  border-right: 1px solid grey;

  @media (max-width: 1023px) {
    justify-content: flex-start;
    width: 100%;
    padding: 0;
    border: none;
  }
`;
const Month = styled.div`
  text-transform: uppercase;
  color: var(--blue1);
  font-size: 12px;
  padding-bottom: 5px;
`;
const Day = styled.div`
  font-size: 15px;
  color: var(--blue1);
  margin-bottom: 1em;
`;

const Time = styled.div`
  font-size: 12px;
  padding: 2px 2px 2px 7px;
`;

const EventDescription = styled.div`
  align-self: center;
  font-size: 12px;
  color: var(--blue1);
  padding-left: 15px;
  width: 50%;
  text-align: left;
  padding-left: 25px;
  line-height: 120%;
  cursor: pointer;
  font-family: "AntennaRegular" !important;

  @media (max-width: 1023px) {
    margin: 1em 0 1em -1em;
    width: 100%;
    padding-left: 10px;
    align-self: unset;
    font-size: 13px;
  }
`;

const Image = styled.img`
  width: 60px;
  height: 60px;
  object-fit: none;
`;
const Details = styled.div`
  flex-grow: 1;
  height: 100%;
  padding: 0 40px;
  color: var(--blue1);
`;
const Summary = styled.div`
  font-size: 13px;
  margin-bottom: 25px;
  font-weight: 500;
`;
const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  margin-right: 20px;
  color: var(--grey1);
  background-color: var(--blue1);
  border-radius: 25px;
  padding: 7px 15px 7px 12px;
`;

const TimeDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  margin-right: 20px;
  color: var(--grey1);
  background-color: var(--blue1);
  border-radius: 25px;
  padding: 5px 15px 5px 8px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Span = styled.span`
  color: #577bb4;
  white-space: nowrap;
`;
