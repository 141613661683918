import { Facebook, Instagram, TikTok, Twitter } from "Components";
import { FC } from "react";
import styled from "styled-components";
import machPic from "../../../../../Assets/~mach-e.png";

import type { Event as EventType } from "../../MarketContext";

export const Content: FC<{
  event: EventType;
  onClick?: () => unknown;
}> = ({ event, ...props }) => {
  return (
    <Container {...props}>
      <Date>
        <Month>{event.start.monthShort}</Month>
        <Day>{event.start.day}</Day>
      </Date>
      <ImgContainer>
        <Image
          src={event.image ?? machPic}
          onError={(event) =>
            ((event.target as HTMLElement).style.display = "none")
          }
        />
      </ImgContainer>
      <Details>
        <Summary>{event.summary}</Summary>
        <SocialIcons>
          {event.isTikTok ? (
            <>
              <TikTok />
            </>
          ) : (
            <>
              <Facebook />
              <Twitter />
              <Instagram />
            </>
          )}
        </SocialIcons>
      </Details>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: row;
  background: var(--grey1);
  padding: 20px;
  border-bottom: 2px solid var(--grey3);
  align-items: center;
  justify-content: baseline;
`;

const Date = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px 0 20px;
  width: 90px;
`;

const Month = styled.div`
  text-transform: uppercase;
  color: var(--blue1);
  font-size: 12px;
  padding-bottom: 4px;
`;

const Day = styled.div`
  font-size: 22px;
  color: var(--blue1);
`;

const Image = styled.img`
  width: 120px;
  height: 80px;
  object-fit: stretch;
`;

const ImgContainer = styled.div``;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 0 40px;
  color: var(--blue1);
  margin: 0;
`;

const Summary = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-top: 2px;
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0 0 0;
`;
