import {
  CalendarProvider,
  FirebaseProvider,
  GoogleMapProvider,
  ModalProvider,
} from "Components";
import { BrowserRouter as Router } from "react-router-dom";
import { LocationProvider } from "Components/LocationContext";
import { InternalRoutes as Routes } from "Routes";

function App() {
  return (
    <Router>
      <FirebaseProvider>
        <LocationProvider>
          <GoogleMapProvider>
            <ModalProvider>
              <CalendarProvider>
                <Routes />
              </CalendarProvider>
            </ModalProvider>
          </GoogleMapProvider>
        </LocationProvider>
      </FirebaseProvider>
    </Router>
  );
}

export default App;
