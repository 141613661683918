import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

interface LocationError {
  error: boolean;
  message?: string;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

interface LocationContextProps {
  location?: Coordinates;
  setLocation: React.Dispatch<React.SetStateAction<Coordinates>>;
  locationLoading: boolean;
  allowLocationSearch: boolean;
  setAllowLocationSearch: React.Dispatch<React.SetStateAction<boolean>>;
  handleLocationRequest: () => void;
  hasLocationError: boolean;
}

const LocationContext = createContext<LocationContextProps>(undefined);

export const LocationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [location, setLocation] = useState<Coordinates>(null);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [allowLocationSearch, setAllowLocationSearch] = useState<boolean>(true);
  const [locationError, setLocationError] = useState<LocationError>({
    error: false,
  });

  const options: PositionOptions = {
    enableHighAccuracy: false,
    timeout: 10000,
    maximumAge: 10000,
  };

  const handleError = (error: GeolocationPositionError) => {
    const { code } = error;

    let message: LocationError;
    if (code === 1) {
      // permission denied
      message = {
        error: true,
        message: "You must allow permissions for us to find events near you.",
      };
    } else if (code === 2) {
      // position unavailable
      message = {
        error: true,
        message: "We couldn't seem to find your location, please try again.",
      };
    } else {
      // timeout
      message = {
        error: true,
        message: "We've encountered a server issue, please try again.",
      };
    }
    setLocationError(message);
    setLocationLoading(false);
  };

  const hasLocationError = locationError.error;

  const handleSuccess = (geolocationPosition: GeolocationPosition) => {
    const {
      coords: { latitude, longitude },
    } = geolocationPosition;
    setLocation({ latitude, longitude });
    setLocationError({
      error: false,
    });
    setLocationLoading(false);
  };

  const handleLocationRequest = () => {
    if (!!location) setLocation(null);
    setLocationLoading(true);
    setLocationError({
      error: false,
    });
    navigator.geolocation.getCurrentPosition(
      handleSuccess,
      handleError,
      options
    );
  };

  useEffect(() => {
    if (!location && allowLocationSearch) {
      handleLocationRequest();
    }
  }, []);

  const value = {
    location,
    handleLocationRequest,
    hasLocationError,
    locationLoading,
    setLocation,
    allowLocationSearch,
    setAllowLocationSearch,
  };

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => useContext(LocationContext);
