import * as React from "react";
import { SVGProps } from "react";
export const Facebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18,10 C18,5.58171875 14.4182812,2 10,2 C5.58171875,2 2,5.58171875 2,10 C2,13.9930312 4.92548437,17.3026562 8.75,17.9028125 L8.75,12.3125 L6.71875,12.3125 L6.71875,10 L8.75,10 L8.75,8.2375 C8.75,6.2325 9.94434375,5.125 11.7717187,5.125 C12.6469844,5.125 13.5625,5.28125 13.5625,5.28125 L13.5625,7.25 L12.5537187,7.25 C11.5599219,7.25 11.25,7.86667187 11.25,8.49932812 L11.25,10 L13.46875,10 L13.1140625,12.3125 L11.25,12.3125 L11.25,17.9028125 C15.0745156,17.3026562 18,13.9930312 18,10"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
