export const fdafMap = {
  "North Texas Ford": "north-texas",
  "Greater Texas Ford": "greater-texas",
  "Houston Ford": "houston",
  "South Texas Ford": "south-texas",
  "Midsouth Ford": "memphis",
  "Cincinnatti North Ford Dealers": "cincinnati-north",
  "St Louis Ford": "st-louis",
};

export const fdafMapInverse = Object.fromEntries(
  Object.entries(fdafMap).map((a) => a.reverse())
);
