import * as React from "react";
import { SVGProps } from "react";
export const Location = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10,2 C13.3137085,2 16,4.55837286 16,7.71428571 C16,10.8701986 13.6,14.5714286 10,18 C6.4,14.5714286 4,10.8701986 4,7.71428571 C4,4.55837286 6.6862915,2 10,2 Z M10,3 C7.22795817,3 5,5.12186492 5,7.71428571 C5,10.2132914 6.80164605,13.3762077 10,16.6003718 C13.1983539,13.3762077 15,10.2132914 15,7.71428571 C15,5.12186492 12.7720418,3 10,3 Z M10,4 C12.209139,4 14,5.790861 14,8 C14,10.209139 12.209139,12 10,12 C7.790861,12 6,10.209139 6,8 C6,5.790861 7.790861,4 10,4 Z M10,5 C8.34314575,5 7,6.34314575 7,8 C7,9.65685425 8.34314575,11 10,11 C11.6568542,11 13,9.65685425 13,8 C13,6.34314575 11.6568542,5 10,5 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
