import React from "react";
import "./reset.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import LogRocket from "logrocket";
import TagManager from "react-gtm-module";
if (process.env.NODE_ENV === "production") {
  LogRocket.init("fyqxo2/ford-cares");
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
  });
}
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
