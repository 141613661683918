import { useContext, useEffect, useState, createContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useFirebaseContext, useCalendar } from "Components";
import { doc, DocumentData } from "firebase/firestore";
import { DateTime } from "luxon";
import type ApiCalendar from "react-google-calendar-api";

export interface Event {
  created: string;
  description: string;
  eventType: string;
  end?: DateTime;
  id: string;
  location: string;
  start: DateTime;
  summary: string;
  city?: string;
  image?: string;
  isAllDay?: boolean;
  isTikTok?: boolean;
}

export const Socials = ["tiktok", "facebook", "twitter", "instagram"] as const;
export type Social = typeof Socials[number];

export interface Tokens {
  socials: Social[];
  images: string[];
}

const getImageUrl = (
  name: string,
  url = process.env.REACT_APP_ASSET_URL || window.location.origin
) => new URL(name, url.endsWith("/") ? url : `${url}/`).toString();

const classifyTokens = (tokens: string[]): Tokens => ({
  socials: tokens.filter((token): token is Social =>
    Socials.includes(token as Social)
  ),
  images: tokens
    .filter((token) => token !== "tiktok")
    .map((token) => getImageUrl(`~${token}`)),
});

const getTokens = (
  content: string
): [ReturnType<typeof classifyTokens>, string] => {
  content = content || "";
  const tokens = classifyTokens(
    (content.toLowerCase().match(/~([^\s]+)/g) || []).map((token) =>
      token.substring(1)
    )
  );
  return [tokens, content.replaceAll(/(~[^\s]+[\s]*)/g, "").trim()];
};

const formatEvent = (event: any): Event | void => {
  const match = event.location?.match(/, (?<name>[^,]+, [^ ]{2}) [\d]{5}/);
  if (match) {
    event.city = match.groups.name;
  }

  if (event?.start?.date) {
    event.start = DateTime.fromISO(event.start.date);
    event.isAllDay = true;
  } else if (event?.start?.dateTime) {
    event.start = DateTime.fromISO(event.start.dateTime);
  } else {
    // Invalid event
    return;
  }

  if (event?.end?.date) {
    event.end = DateTime.fromISO(event.end.date);
    event.isAllDay = true;
  } else if (event?.end?.dateTime) {
    event.end = DateTime.fromISO(event.end.dateTime);
  }

  const [tokens, description] = getTokens(event.description);

  return {
    created: event.created || "",
    description: description,
    eventType: event.eventType || "",
    id: event.id || "",
    location: event.location || "",
    start: event.start,
    summary: event.summary || "",
    city: event.city,
    isAllDay: event.isAllDay || false,
    isTikTok: tokens.socials.includes("tiktok"),
    image: tokens.images.shift(),
    end: event.end,
  };
};

interface MarketProps {
  events: Event[];
  content: Event[];
  market: string;
  document: DocumentData;
}

const loadEvents = async (
  calendar: ApiCalendar,
  calendarId: string
): Promise<Event[]> => {
  const {
    result: { items },
  } = await calendar.listUpcomingEvents(30, calendarId);
  return (Array.isArray(items) ? items : [])
    .map(formatEvent)
    .filter((e): e is Event => !!e);
};

const MarketContext = createContext<MarketProps>(undefined);

export const MarketProvider = (props) => {
  const { market } = useParams();
  const navigate = useNavigate();
  const { db } = useFirebaseContext();
  const calendar = useCalendar();
  const [document, l] = useDocumentData(doc(db, "ford_markets", market));
  const [events, setEvents] = useState<Event[]>([]);
  const [content, setContent] = useState<Event[]>([]);
  useEffect(() => {
    if (!l && !document) {
      navigate("/", { replace: true });
    }
  }, [l, document, navigate]);
  useEffect(() => {
    if (calendar && document?.eventCalendar) {
      loadEvents(calendar, document.eventCalendar).then(setEvents);
    }
  }, [calendar, document?.eventCalendar]);

  useEffect(() => {
    if (calendar && document?.contentCalendar) {
      loadEvents(calendar, document.contentCalendar).then(setContent);
    }
  }, [calendar, document?.contentCalendar]);

  if (!document) {
    return null;
  }

  return (
    <MarketContext.Provider
      value={{ market, document, events, content }}
      {...props}
    />
  );
};

export const useMarket = () => useContext(MarketContext);
