import * as React from "react";
import { SVGProps } from "react";
export const TikTok = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={36}
    height={36}
    viewBox="-1 -1 33 33"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      d="M21.227713 3c.4290139 3.68951971 2.4882807 5.88919107 6.0685969 6.12319866v4.14973464c-2.0748673.2028066-3.8923263-.4758154-6.0061949-1.7550569v7.7612518c0 9.8595199-10.7487487 12.9406198-15.07008891 5.8735905C3.443136 20.6051712 5.14359117 12.6255123 14.0514802 12.3057019v4.375942c-.678622.1092035-1.4040456.2808091-2.0670671.5070164-1.9812643.6708218-3.10450071 1.9266626-2.79249059 4.1419344.60061949 4.2433377 8.38527209 5.4991784 7.73785109-2.7924906V3.00780025h4.2979394V3Z"
    />
  </svg>
);
