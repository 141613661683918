import { Routes, Route } from "react-router-dom";
import { Landing } from "./Landing";
import { Market } from "./Market";
import { NoMatch } from "./NoMatch/NoMatch";

export const InternalRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/:market" element={<Market />} />
      <Route path="/nomatch" element={<NoMatch />} />
    </Routes>
  );
};
